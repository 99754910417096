import * as React from "react"
import { useEffect } from "react"
import Typography from "/src/components/ui/base/typography"
import Header from "/src/components/ui/base/layout/header"
import { useTranslation } from "react-i18next"
import "moment/locale/it"
import Section from "../../components/ui/base/Section"
import { createMarkup } from "../../util/tour"
import GatsbyImage from "gatsby-image"
import Button from "../../components/ui/base/Button"
import { ACADEMY_URL, BASE_URL, COURSES_URL } from "../../util/constants"
import Breadcrumbs from "../../components/ui/base/Breadcrumbs"
import CoachCard from "../../components/ui/base/CoachCard"
import classnames from "classnames"
import * as styles from "./index.module.scss"
import { GatsbySeo } from "gatsby-plugin-next-seo/src/meta/gatsby-seo"
import { FAQJsonLd, ProductJsonLd } from "gatsby-plugin-next-seo"
import moment from "moment"
import { Col, Container, Row } from "react-bootstrap"
import { graphql } from "gatsby"
import Faq from "../../components/ui/base/Faq"
import GallerySection from "../../components/ui/extended/GallerySection"
import ReviewCard from "../../components/ui/base/ReviewCard"
import CourseCard from "../../components/ui/base/CourseCard"
import IconWithTextSection from "../../components/ui/base/IconWithTextSection"

const AcademyPage = ({ data }) => {
  const { t, i18n: l } = useTranslation()

  const academy = data.strapiAcademy
  const academyLink = `${BASE_URL[l.language]}/${ACADEMY_URL[l.language]}/`

  return (
    <>
      <Header
        theme="dark"
        img={academy?.cover?.localFile.childImageSharp.gatsbyImageData}
      >
        <Container fluid="xl">
          <Row className="align-items-center">
            <Col lg={12}>
              <div className={classnames([styles.header])}>
                <Typography className="animated fadeInDown" variant="heading1">
                  {academy.title}
                </Typography>
                <Typography className="animated fadeInDown" variant="heading2">
                  {academy.subtitle}
                </Typography>
              </div>
            </Col>
          </Row>
        </Container>
      </Header>

      <Section
        variant={"white"}
        title={"Queste sono le foto che i corsisti dell'accademia"}
        subtitle={
          "Passa dalle basi della fotografia ad essere pubblicato su giornali internazionali in meno di 6 mesi con il sistema WeShoot.\n"
        }
      >
        <GallerySection pictures={academy.topGallery} />
      </Section>

      <Section variant={"white"} title={"Cosa é WeShoot?"}>
        <p>
          Vuoi portare a casa lo scatto perfetto? Vuoi far fare Wow alle persone
          a cui mostri i tuoi scatti? Se la risposta a queste domande é SI,
          allora il metodo WeShoot é quello che stavi cercando.
        </p>
        <p>
          WeShoot é il punto di riferimento Italiano per la fotografia di
          paesaggio. É quella community di persone che avremmo sempre voluto
          trovare al nostro fianco quando abbiamo imparato la fotografia più di
          10 anni fa.
        </p>
        <p>
          Se anche tu quando vedi quelle foto incredibili su Instagram dei
          paesaggi da urlo vuoi capire cosa succede dietro alle quinte per
          realizzarle, allora sei nel posto giusto.
        </p>
        <p>
          WeShoot ha sviluppato negli ultimi 10 anni un metodo di insegnamento
          che abbiamo portato sul campo durante i viaggi fotografici, a lezione
          in aula e durante ogni webinar sul gruppo di Facebook.
        </p>

        <p>
          Lo stesso metodo che ha portato Carlo, Marco e molti altri ad essere
          pubblicati su riviste internazionali.
        </p>
      </Section>
    </>
  )
}

export default AcademyPage

export const query = graphql`
  {
    strapiAcademy {
      title
      subtitle
      topGallery {
        localFile {
          ...ImageFragment
        }
      }
      cover {
        localFile {
          ...ImageFragment
        }
      }
    }
  }
`
